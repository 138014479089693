/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { Spin, Button } from "antd";
import moment from "moment";
import momentTimezone from "moment-timezone";
import Image from "next/image";
import router from "next/router";
import 'animate.css';
// import { Modal } from "react-bootstrap";
import { Modal as SearchModal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Cursor from "./Cursor";
import styles from "./Header.module.css";
import NavBarComponent from "./NavBar";
import { emailRegx, otpRegx, userName,
  USAphoneRegx,
  phoneRegx,
  passwordReg,
  httpUrl, } from "../../helper/RegularExp/RegularExp";
import { ReactToastify } from "../../helper/ReusableComponents";
import {
  checkIsObjectEmpty,
  getGooglePlaceObjectValues,
  // clearLocalStorage
} from "../../helper/service";
import { onLogout, LoginAction } from "../../store/actions";
import { AxiosInstance, logout } from "../../utilities";
import ForgotPasswordComponent from "../ForgotPasswordComponent/ForgotPasswordComponent";
import LoginComponent from "../LoginComponent/LoginComponent";
import LoginWithOtpComponent from "../LoginComponent/LoginWithOtpComponent";
import OtpVerifyComponent from "../LoginComponent/OtpVerifyComponent";
import MegaSearchComponent from "../MegaSearchComponent/MegaSearchComponent";
import SignUpComponent from "../SignUpComponent/SignUpComponent";

export function useOutsideAlerter(initialScrollPosition) {
  const ref = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(initialScrollPosition);

  useEffect(() => {
    function listenToScroll() {
      const position = window.pageYOffset;
      setScrollPosition(position);
    }

    // Bind the event listener
    window.addEventListener("scroll", listenToScroll);
    return () => {
      // Unbind the event listener on clean up
      window.removeEventListener("scroll", listenToScroll);
    };
  }, [ref]);
  return { ref, scrollPosition, setScrollPosition };
}

const HomeHeaderComponent = (props) => {
  const vidRef = useRef(null);
  const headerOverlayRef = useRef(null);

  const dispatch = useDispatch();
  const search = useSelector((state) => state.search);
  const auth = useSelector((state) => state.auth);
  const loader = useSelector((state) => state.auth.loading);

  const [spin, setSpin] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const { ref, scrollPosition, setScrollPosition } = useOutsideAlerter(0);
  const [videoPlayingFullScreen, setVideoPlayingFullScreen] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [isAuthenticated, setIsAuthenticate] = useState(false);

  const [headerSearch, setHeaderSearch] = useState({
    event_date: "",
    event_type: "wedding",
    event_place: "",
  });
  const [headerSearchError, setHeaderSearchError] = useState({
    event_date: "",
    event_place: "",
    event_type: "",
  });
  const [eventPlace, setEventPlace] = useState({
    event_place: {
      lat: "",
      lng: "",
      address: "",
    },
  });

  const [otpScreen, setOtpScreen] = useState({ id: "", type: "" });
  const [loginModel, setLoginModel] = useState(false);
  const [visible, setVisible] = useState(false);
  const [signupModel, setSignupModel] = useState(false);
  const [otpModel, setOtpModel] = useState(false);
  const [otpForm, setOtpForm] = useState({ otp: "" });
  const [otpVerify, setOtpVerify] = useState(false);
  const [forgotModel, setForgotModel] = useState(false);
  const [formError, setformError] = useState({
    user_name: "",
    email: "",
    password: "",
    phone: "",
    name: "",
    otp: "",
    email_phone: "",
  });
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const [signUpForm, setSignUpForm] = useState({
    email: "",
    password: "",
    phone: {
      countryCode: "",
      number: "",
    },
    name: "",
    firstName: "",
    lastName: "",
    isEventPlanner: false,
    companyName: "",
    companyLink: "",
  });
  const [checkRadio, setCheckRadio] = useState(false);
  const onChangeRadio = (data) => {
    if (!data) {
      setformError({ ...formError, companyName: "", companyLink: "" });
    }
    setCheckRadio(data);
  };
  const [forgotForm, setForgotForm] = useState({
    email_phone: "",
  });

  const [show, setShow] = useState(false);
  const [showModalForWeb, setShowModalForWeb] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (auth && auth.token) {
      closeModel();
      setLoginModel(false);
      setIsAuthenticate(true);
    } else {
      setIsAuthenticate(false);
    }
    if (auth && auth.otp !== null) {
      setLoginModel(false);
      setOtpVerify(true);

      otpScreen.id = auth.otpId;
      otpScreen.type = 1;
      setOtpScreen(otpScreen);
    }
  }, [auth]);

  useEffect(() => {
    setTimeZone(momentTimezone.tz.guess());
  }, []);

  useEffect(() => {
    if (search?.searchData) {
      const headerSearchClone = { ...headerSearch };
      headerSearchClone.event_type = search.searchData.eventType;
      headerSearchClone.event_date = moment(search.searchData.date);
      headerSearchClone.event_place = search.searchData.event_place
        ? search.searchData?.event_place?.replace(/\+/g, " ")
        : "";
      setHeaderSearch(headerSearchClone);
      const eventPlaceClone = { ...eventPlace };
      eventPlaceClone.event_place.lat = search.searchData.latitude;
      eventPlaceClone.event_place.lng = search.searchData.longitude;
      eventPlaceClone.event_place.address = search.searchData.event_place
        ? search.searchData?.event_place?.replace("+", " ")
        : "";
      setEventPlace(eventPlaceClone);
    }
  }, [search]);

  const handlePlayClicked = () => {
    vidRef.current.muted = false;
    setVideoPlayingFullScreen(true);
    document.body.classList.add(styles.video_playing);
    headerOverlayRef.current.classList.add(styles.header_overlay_play);
  };

  const handlePauseClicked = () => {
    vidRef.current.muted = true;
    setVideoPlayingFullScreen(false);
    document.body.classList.remove(styles.video_playing);
    headerOverlayRef.current.classList.remove(styles.header_overlay_play);
  };

  const disabledDate = (current) => {
    return current && current < moment().add(45, "day");
  };

  const onDatePickerSelect = (date) => {
    const headerSearchClone = { ...headerSearch };
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchClone.event_date = moment(date._d);
    headerSearchErrorClone.event_date = "";
    setHeaderSearch(headerSearchClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const onSelectChangeHandler = (value) => {
    const headerSearchClone = { ...headerSearch };
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchErrorClone.event_type = "";
    headerSearchClone.event_type = value;
    setHeaderSearch(headerSearchClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const onClickSearchBtn = async (e) => {
    e?.preventDefault();
    const headerSearchErrorClone = { ...headerSearchError };
    if (headerSearch.event_date === "") {
      headerSearchErrorClone.event_date = "Required";
    } else {
      headerSearchErrorClone.event_date = "";
    }
    if (
      Object.values(eventPlace.event_place).every((x) => x === "" || x === null)
    ) {
      headerSearchErrorClone.event_place = "Required";
    } else {
      headerSearchErrorClone.event_place = "";
    }

    if (
      headerSearch.event_type !== undefined &&
      headerSearch.event_type.trim() === ""
    ) {
      headerSearchErrorClone.event_type = "Required";
    } else if (headerSearch.event_type === undefined) {
      headerSearchErrorClone.event_type = "Required";
    } else {
      headerSearchErrorClone.event_type = "";
    }
    setHeaderSearchError(headerSearchErrorClone);

    const isEmpty = await checkIsObjectEmpty(headerSearchErrorClone);

    if (isEmpty) {
      const headerSearchClone = { ...headerSearch };
      const start_date = moment
        .tz(moment(headerSearch.event_date._d).format("YYYY-MM-DD"), timeZone)
        .utc();
      const start_date_with_tz = moment
        .tz(
          moment(headerSearch.event_date._d).format("YYYY-MM-DD 00:00:00"),
          timeZone
        )
        .utc();

      let data = {
        date: start_date_with_tz._d,
        eventType: headerSearchClone.event_type,
        latitude: eventPlace.event_place.lat,
        longitude: eventPlace.event_place.lng,
        timeZone: timeZone,
      };

      localStorage.setItem("searchData", JSON.stringify(data));
      const addressSlug = eventPlace.event_place.address
        .split(", ")
        .join("+")
        .split(" ")
        .join("+")
        .split(".")
        .join("")
        .split("-")
        .join("")
        .split("++")
        .join("+");
      const slug = `/search-results/${headerSearchClone.event_type}/${moment(
        start_date._d
      ).format("YYYY")}/${moment(start_date._d).format("MM")}/${moment(
        start_date._d
      ).format("DD")}/${addressSlug}/@${
        eventPlace.event_place.lat + "," + eventPlace.event_place.lng
      }`;
      router.push({
        pathname: slug,
        state: {
          searchData: data,
        },
      });
    }
    if (typeof window !== 'undefined' && !["development", "local"].includes(process.env.REACT_APP_NODE_ENV)) {
      window.gtag('event', 'EventSearch', {
        'event_category': 'EventSearch',
        'event_label': 'Ran an Availability Search',
        'event_id': 'Goal ID 7 / Goal Set 2',
        'event_action': 'EventSearch'
      });
    }
  };

  const onGooglePlaceSelect = (place) => {
    let formatedData = getGooglePlaceObjectValues(place);
    const eventPlaceClone = { ...eventPlace };
    const addressSlug = formatedData.address
      .split(", ")
      .join("+")
      .split(" ")
      .join("+")
      .split(".")
      .join("")
      .split("-")
      .join("")
      .split("++")
      .join("+");
    eventPlaceClone.event_place.lat = formatedData.lat;
    eventPlaceClone.event_place.lng = formatedData.lng;
    eventPlaceClone.event_place.address = formatedData.address;
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchErrorClone.event_place = "";
    setEventPlace(eventPlaceClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const logoutFunction = () => {
    dispatch(onLogout());
    logout();
    toast.success(
      <ReactToastify title="Success" desc="Logged out successfully." />
    );
  };

  const openModel = () => {
    closeModel();
    setLoginModel(true);
    setSignupModel(false);
    setformError();
    setOtpModel(false);
    setForgotModel(false);
  };
  const openSignUpModel = () => {
    closeModel();
    const formErrorClone = {
      user_name: "",
      email: "",
      password: "",
      phone: "",
      name: "",
      otp: "",
      email_phone: "",
    };
    const signUpFormClone = {
      email: "",
      password: "",
      phone: {
        countryCode: "",
        number: "",
      },
      name: "",
      firstName: "",
      lastName: "",
      isEventPlanner: false,
      companyName: "",
      companyLink: "",
    };

    setSignupModel(true);
    setLoginModel(false);
    setSignUpForm(signUpFormClone);
    setformError(formErrorClone);
    setOtpVerify(false);
  };

  const closeModel = () => {
    setSpin(false);
    const signUpFormNew = { ...signUpForm };
    const forgotFormNew = { ...forgotForm };
    const loginFormNew = { ...loginForm };
    signUpFormNew.firstName = "";
    signUpFormNew.lastName = "";
    signUpFormNew.email = "";
    signUpFormNew.phone.number = "";
    signUpFormNew.phone.countryCode = "";
    signUpFormNew.password = "";
    signUpFormNew.isEventPlanner = false;
    signUpFormNew.companyName = "";
    signUpFormNew.companyLink = "";

    forgotFormNew.email_phone = "";

    loginFormNew.email = "";
    loginFormNew.password = "";
    setVisible(false);
    setLoginForm(loginFormNew);
    setForgotForm(forgotFormNew);
    setSignUpForm(signUpFormNew);
    setLoginModel(false);
    setSignupModel(false);
    setformError();
    setOtpModel(false);
    setOtpVerify(false);
    setForgotModel(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "Please enter an email address")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter a valid email address")
          : (newformError.email = "");
        break;
      case "password":
        value === ""
          ? (newformError.password = "Please enter a password")
          : (newformError.password = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setLoginForm({ ...loginForm, [name]: value });
  };

  const onLogin = (e) => {
    const loginData = {
      userName: loginForm.email,
      password: loginForm.password,
      deviceToken: localStorage.getItem("deviceToken") || null
    };
    e.preventDefault();
    const newformError = { ...formError };
    const loginFormNew = { ...loginForm };
    if (loginFormNew.email === "" && loginFormNew.password === "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "* Please enter a password";
    } else if (loginFormNew.email === "" && loginFormNew.password !== "") {
      newformError.email = "* Please enter an email address";
      newformError.password = "";
    } else if (loginFormNew.email !== "" && loginFormNew.password === "") {
      if (!emailRegx.test(loginFormNew.email)) {
        newformError.email = "* Please enter a valid email address";
      } else {
        newformError.email = "";
      }
      newformError.password = "* Please enter a password";
    } else if (
      loginFormNew.email !== "" &&
      !emailRegx.test(loginFormNew.email)
    ) {
      newformError.email = "* Please enter a valid email address";
      newformError.password = "";
    } else {
      newformError.email = "";
      newformError.password = "";
    }
    setformError(newformError);
    if (newformError.email === "" && newformError.password === "") {
      dispatch(LoginAction(loginData, "/client/login"));
    }
  };

  const onInputSignUpChange = (e) => {
    const { name, value, checked } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email":
        value.trim() === ""
          ? (newformError.email = "")
          : !emailRegx.test(value.trim())
          ? (newformError.email = "Please enter a valid email address")
          : (newformError.email = "");
        break;
      case "password":
        value === ""
          ? (newformError.password = "")
          : !passwordReg.test(value.trim())
          ? (newformError.password =
              "Password should be at least 8 characters long, contain at least 1 number, 1 capital letter and 1 special character (! @ # $ % * & -)")
          : (newformError.password = "");
        break;
      case "firstName":
        value.trim() === ""
          ? (newformError.firstName = "")
          : !userName.test(value.trim())
          ? (newformError.firstName = "Please enter a valid first name")
          : (newformError.firstName = "");
        break;
      case "lastName":
        value.trim() === ""
          ? (newformError.lastName = "")
          : !userName.test(value.trim())
          ? (newformError.lastName = "Please enter a valid last name")
          : (newformError.lastName = "");
        break;
      case "companyName":
        if (checkRadio) {
          value.trim() === ""
            ? (newformError.companyName = "")
            : !userName.test(value.trim())
            ? (newformError.companyName = "Please enter a valid company name")
            : (newformError.companyName = "");
        }
        break;
      case "companyLink":
        if (checkRadio) {
          value.trim() === ""
            ? (newformError.companyLink = "")
            : !httpUrl.test(value.trim())
            ? (newformError.companyLink = "Please enter a valid Company link")
            : (newformError.companyLink = "");
        }
        break;
      // case "phone":
      //   value.trim() === ""
      //     ? (newformError.phone =
      //       "Please enter  phone no")
      //     : !USAphoneRegx.test(value.trim())
      //       ? (newformError.phone = "Please enter valid phone no")
      //       : (newformError.phone = "");
      //   break;
      default:
        break;
    }

    if (name === "isEventPlanner") {
      setSignUpForm({ ...signUpForm, [name]: checked });
      if (!checked) {
        newformError.companyName = "";
        newformError.companyLink = "";
      }
    } else {
      setSignUpForm({ ...signUpForm, [name]: value });
    }
    setformError(newformError);
  };

  const onPhoneInputChanges = (value, country, e, formattedValue) => {
    if (e.target) {
      const { name } = e.target;
      const formErrorNew = { ...formError };
      switch (name) {
        case "phone":
          formattedValue.trim() === ""
            ? (formErrorNew.phone = "")
            : !phoneRegx.test(formattedValue.trim()) &&
              formattedValue.trim() !== ""
            ? (formErrorNew.phone = "Please enter a valid phone number")
            : (formErrorNew.phone = "");
          break;
        default:
          break;
      }

      let user_detail = {};
      user_detail["number"] = formattedValue;
      user_detail["countryCode"] = "+" + country.dialCode;
      setformError(formErrorNew);
      setSignUpForm({ ...signUpForm, [name]: user_detail });
    }
  };

  const onSignUp = (e) => {
    e.preventDefault();
    const newformError = { ...formError };

    const signUpFormNew = { ...signUpForm };
    if (signUpFormNew.firstName.trim() === "") {
      newformError.firstName = "Please enter a first name";
    } else if (
      !userName.test(signUpFormNew.firstName.trim()) &&
      signUpFormNew.firstName.trim() !== ""
    ) {
      newformError.firstName = "Please enter a valid first name";
    } else {
      newformError.firstName = "";
    }

    if (signUpFormNew.lastName.trim() === "") {
      newformError.lastName = "Please enter a last name";
    } else if (
      !userName.test(signUpFormNew.lastName.trim()) &&
      signUpFormNew.lastName.trim() !== ""
    ) {
      newformError.lastName = "Please enter a valid last name";
    } else {
      newformError.lastName = "";
    }

    if (signUpFormNew.email.trim() === "") {
      newformError.email = "Please enter an email address";
    } else if (
      !emailRegx.test(signUpFormNew.email.trim()) &&
      signUpFormNew.email.trim() !== ""
    ) {
      newformError.email = "Please enter a valid email address";
    } else {
      newformError.email = "";
    }
    if (
      Object.values(signUpFormNew.phone).every((x) => x === "" || x === null)
    ) {
      newformError.phone = "Please enter a phone number";
    } else if (
      !phoneRegx.test(signUpFormNew.phone.number.trim()) &&
      signUpFormNew.phone.number.trim() !== ""
    ) {
      newformError.phone = "Please enter a valid phone number";
    } else {
      newformError.phone = "";
    }
    if (signUpFormNew.password.trim() === "") {
      newformError.password = "Please enter a password";
    } else if (
      !passwordReg.test(signUpFormNew.password.trim()) &&
      signUpFormNew.password.trim() !== ""
    ) {
      newformError.password =
        "Password should be at least 8 characters long, contain at least 1 number, 1 capital letter and 1 special character (! @ # $ % * & -)";
    } else {
      newformError.password = "";
    }
    if (checkRadio) {
      if (signUpFormNew?.companyName.trim() === "") {
        newformError.companyName = "Please enter a company name";
      } else if (
        !userName.test(signUpFormNew.companyName.trim()) &&
        signUpFormNew.companyName.trim() !== ""
      ) {
        newformError.companyName = "Please enter a company name";
      } else {
        newformError.companyName = "";
      }

      if (signUpFormNew?.companyLink.trim() === "") {
        newformError.companyLink = "Please enter a valid URL";
      } else if (
        !httpUrl.test(signUpFormNew.companyLink.trim()) &&
        signUpFormNew.companyLink.trim() !== ""
      ) {
        newformError.companyLink = "Please enter a valid URL";
      } else {
        newformError.companyLink = "";
      }
    } else {
      newformError.companyName = "";
      newformError.companyLink = "";
    }

    setformError(newformError);

    const isEmpty = Object.values(newformError).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      setSpin(true);
      let data = {
        firstName: signUpForm.firstName,
        lastName: signUpForm.lastName,
        email: signUpForm.email,
        password: signUpForm.password,
        number: signUpForm.phone.number,
        countryCode: signUpForm.phone.countryCode,
        isEventPlanner: checkRadio,
      };

      if (checkRadio) {
        data.companyName = signUpForm.companyName;
        data.companyLink = signUpForm.companyLink;
      }
      AxiosInstance(4007, "application/json")
        .post("/register/user", data)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            const otpScreenClone = { ...otpScreen };
            otpScreenClone.id = response.data.id;
            otpScreenClone.type = 0;
            setOtpScreen(otpScreenClone);
            toast.success(
              <ReactToastify title="Success" desc={response.msg} />
            );
            setSignupModel(false);
            setOtpForm({ otp: "" });
            setOtpVerify(true);
            setSpin(false);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            toast.error(<ReactToastify title="Error" desc={response.msg} />);
            setSpin(false);
          }
        })
        .catch((err) => {
          var error = err && err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            logout();
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            console.log("Error", error.msg);
            setSpin(false);
          }
        });
    }
  };

  const onGetOtp = (e) => {
    e.preventDefault();
    const newformError = { ...formError };
    const signUpFormNew = { ...signUpForm };

    if (
      Object.values(signUpFormNew.phone).every((x) => x === "" || x === null)
    ) {
      newformError.phone = "Please enter a phone number";
    } else if (
      !phoneRegx.test(signUpFormNew.phone.number.trim()) &&
      signUpFormNew.phone.number.trim() !== ""
    ) {
      newformError.phone = "Please enter a valid phone number";
    } else {
      newformError.phone = "";
    }
    setformError(newformError);
    let data = {
      number: signUpForm.phone.number,
    };
    const isEmpty = Object.values(newformError).every(
      (x) => x === null || x === ""
    );
    if (isEmpty) {
      setSpin(true);
      AxiosInstance(4007, "application/json")
        .post("/client/login/otp", data)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            toast.success(
              <ReactToastify title="Success" desc={response.msg} />
            );
            const otpScreenClone = { ...otpScreen };
            otpScreenClone.type = 1;
            otpScreenClone.id = response.id;
            setOtpScreen(otpScreenClone);
            setOtpForm({ otp: "" });
            setOtpVerify(true);
            setOtpModel(false);
            setSpin(false);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            toast.error(<ReactToastify title="Error" desc={response.msg} />);
            setSpin(false);
          }
        })
        .catch((err) => {
          var error = err && err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            logout();
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            console.log("Error", error.msg);
            setSpin(false);
          }
        });
    }
  };

  const onInputOtpChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "phone":
        value.trim() === ""
          ? (newformError.phone = "Please enter a phone number")
          : !USAphoneRegx.test(value.trim())
          ? (newformError.phone = "Please enter a valid phone number")
          : (newformError.phone = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setSignUpForm({ ...signUpForm, [name]: value });
  };

  const onInputforgotChange = (e) => {
    const { name, value } = e.target;
    const newformError = { ...formError };
    switch (name) {
      case "email_phone":
        value.trim() === ""
          ? (newformError.email_phone = "")
          : !emailRegx.test(value.trim())
          ? (newformError.email_phone = "Please enter an email address")
          : (newformError.email_phone = "");
        break;
      default:
        break;
    }
    setformError(newformError);
    setForgotForm({ ...forgotForm, [name]: value });
  };

  const onSubmitForgot = (e) => {
    e.preventDefault();
    let data = {
      email: forgotForm.email_phone,
    };
    const forFormErrorNew = { ...formError };
    if (forgotForm.email_phone.trim() === "") {
      forFormErrorNew.email_phone = "Please enter an email address";
    } else if (
      !emailRegx.test(forgotForm.email_phone.trim()) &&
      forgotForm.email_phone.trim() !== ""
    ) {
      forFormErrorNew.email_phone = "Please enter a valid email address";
    } else {
      forFormErrorNew.email_phone = "";
    }
    setformError(forFormErrorNew);
    if (forFormErrorNew.email_phone === "") {
      setSpin(true);
      AxiosInstance(4007, "application/json")
        .post("/client/forgotPassword", data)
        .then((resp) => {
          const response = resp && resp.data;
          if (
            response &&
            (response.status === 200 || response.status === "200")
          ) {
            toast.success(
              <ReactToastify title="Success" desc={response.msg} />
            );
            closeModel();
            setSpin(false);
          } else if (
            response &&
            (response.status === 400 || response.status === "400")
          ) {
            toast.error(<ReactToastify title="Error" desc={response.msg} />);
            setSpin(false);
          }
        })
        .catch((err) => {
          var error = err && err.response;
          if (error && (error.status === "403" || error.status === 403)) {
            logout();
          } else if (
            error &&
            (error.status === "500" || error.status === 500)
          ) {
            console.log("Error", error.msg);
            setSpin(false);
          }
        });
    }
  };

  const on_password_visible = () => {
    setVisible(!visible);
  };

  const onClickOtpModel = () => {
    closeModel();
    setOtpModel(true);
    setLoginModel(false);
    setSignupModel(false);
    setformError();
  };

  const onClickforgotModel = (e) => {
    closeModel();
    setForgotModel(true);
    setLoginModel(false);
  };

  const otpCodeChange = (otp) => {
    const otpFormErrorNew = { ...formError };
    switch ("otp") {
      case "otp":
        otp.trim() === ""
          ? (otpFormErrorNew.otp = "")
          : !otpRegx.test(otp.trim())
          ? (otpFormErrorNew.otp = "Please enter a valid OTP")
          : (otpFormErrorNew.otp = "");
        break;
      default:
        break;
    }
    setformError(otpFormErrorNew);
    setOtpForm({ ...otpForm, otp: otp });
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();
    let data = {
      id: otpScreen.id,
      otp: otpForm.otp,
      type: otpScreen.type,
      rememberDevice: true
    };
    const otpFormErrorNew = { ...formError };
    if (otpForm.otp.trim() === "") {
      otpFormErrorNew.otp = "Please enter a OTP";
    } else if (!otpRegx.test(otpForm.otp.trim()) && otpForm.otp.trim() !== "") {
      otpFormErrorNew.otp = "Please enter a valid OTP";
    } else {
      otpFormErrorNew.otp = "";
    }
    setformError(otpFormErrorNew);
    if (otpFormErrorNew.otp === "") {
      dispatch(LoginAction(data, "/client/verifyOtp"));
    }
  };

  const resend_otp_api = () => {
    let data = {
      id: otpScreen.id,
      type: otpScreen.type,
      number: signUpForm.phone.number,
    };
    setSpin(true);
    AxiosInstance(4007, "application/json")
      .post("/resendOtp", data)
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          toast.success(<ReactToastify title="Success" desc={response.msg} />);
          const otpScreenClone = { ...otpScreen };
          otpScreen.id = response.id;
          setOtpScreen(otpScreen);
          setSpin(false);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          toast.error(<ReactToastify title="Error" desc={response.msg} />);
          setSpin(false);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          logout();
        } else if (error && (error.status === "500" || error.status === 500)) {
          console.log("Error", error.msg);
          setSpin(false);
        }
      });
  };

  const { backgroundVideo, heading, subHeading } = props?.cmsData || {};

  return (
    <div>
      <Spin spinning={spin} />
      <header
        className={`d-flex align-items-center ${styles.header} ${
          videoLoaded ? styles.header_menu_video : ""
        } ${showVideo ? styles.play_bg_video : ""} ${
          scrollPosition >= 75 ? styles.header_menu_bg : ""
        } ${scrollPosition >= 300 ? styles.header_menu_search : ""}`}
      >
        <div
          className={styles.header_shadow}
          onClick={
            !isMobile && !videoPlayingFullScreen
              ? handlePlayClicked
              : handlePauseClicked
          }
        ></div>
        {videoLoaded && isMobile && (
          <>
            {!videoPlayingFullScreen ? (
              <>
                <Button
                  className={`${styles.header_video_btn} ${styles.cursor_video_btn} ${styles.header_video_play_btn}`}
                  shape="circle"
                  onClick={handlePlayClicked}
                  icon={
                    <Image
                      src="/assets/images/play_button.svg"
                      width={60}
                      height={60}
                      alt="Play"
                    />
                  }
                />
                <p
                  className={`${styles.header_video_btn} ${styles.header_video_btn_text}`}
                >
                  Play our reel
                </p>
              </>
            ) : (
              <Button
                className={`${styles.header_video_btn} ${styles.cursor_video_btn} ${styles.header_video_pause_btn}`}
                shape="circle"
                onClick={handlePauseClicked}
                icon={
                  <Image
                    src="/assets/images/close_button.svg"
                    width={60}
                    height={60}
                    alt="Close"
                  />
                }
              />
            )}
          </>
        )}
        {/* Video In Mute */}
        <div
          className={styles.header_overlay}
          ref={headerOverlayRef}
          onClick={
            !isMobile && !videoPlayingFullScreen
              ? handlePlayClicked
              : handlePauseClicked
          }
        >
          {!isMobile && videoLoaded && scrollPosition < 600 && (
            <Cursor fullScreen={videoPlayingFullScreen} />
          )}
          {backgroundVideo !== undefined && (
            <div
              className="animate__animated animate__slideInDown animate__delay-3s animate__duration-3s"
              style={{ height: "100%" }}
            >
              {typeof window !== "undefined" && (
                <video
                  id="videoBG"
                  ref={vidRef}
                  key={backgroundVideo}
                  className={`${styles.header_overlay_video} ${
                    isMobile && videoPlayingFullScreen
                      ? styles.header_overlay_video_m
                      : ""
                  } background-video`}
                  width="100%"
                  height="100%"
                  autoPlay
                  loop
                  muted
                  playsInline
                  disablePictureInPicture
                  onLoadedData={() =>
                    setTimeout(() => setVideoLoaded(true), 2800)
                  }
                  src={process.env.IMAGE_LINK + backgroundVideo}
                  type="video/mp4"
                ></video>
              )}
            </div>
          )}
        </div>

        <video className="sr-only" muted>
          <source
            src={process.env.IMAGE_LINK + backgroundVideo}
            type="video/mp4"
          />
        </video>
        <NavBarComponent
          {...props}
          logout={logoutFunction}
          isAuthenticated={isAuthenticated}
          openModel={openModel}
          handleShow={handleShow}
        />
        <div
          className={`d-flex align-items-center justify-content-center flex-column ${styles.main_header}`}
        >
          <div className="animate__animated animate__fadeInUp animate__delay-1s animate__duration-5s">
            <h1 className={styles.main_header_heading}>
              {heading === undefined
                ? "Instant Band Availability and Pricing"
                : heading}
            </h1>
          </div>
          <div className="animate__animated animate__fadeIn animate__delay-2s animate__duration-1s">
            <h2
              className={`animate__animated animate__fadeInDown animate__delay-2s ${styles.main_header_para}`}
            >
              {subHeading === undefined
                ? "Lorem Ipsum is simply dummy text of the printing and typesettin industry."
                : subHeading}
            </h2>
          </div>
          <div
            className="animate__animated animate__fadeInDown animate__delay-2s animate__duration-2.2s pe-auto"
          >
            <button
              style={{ padding: "25px 70px", paddingTop: "15px" }}
              className={`${styles.main_header_big_availability_btn} ${
                showModalForWeb
                  ? styles.main_header_big_availability_btn_disable
                  : ""
              }`}
              onClick={() => setShowModalForWeb(true)}
            >
              <span
                style={{ fontSize: "18px", fontWeight: "normal" }}
                className={`${styles.main_header_big_availability_btn_super_text}`}
              >
                Click Here To See
              </span>
              <br />
              <p className={`mb-0 ${styles.bigger_text}`}>
                Instant Availability and Pricing
              </p>
            </button>
            <div className={styles.nav_section_search_mini_btn_mobile}>
              <button
                className={`d-flex align-items-center ${styles.nav_section_search_mini_btn}`}
                type="button"
                onClick={handleShow}
              >
                {!!(search?.searchData?.eventPlace && search.searchData?.eventType) ? (
                  <>
                    <div
                      className={
                        styles.nav_section_search_mini_btn_text_wrap_mobile
                      }
                    >
                      <span className="slash">
                        {search.searchData?.date?.replace(/\-/g, "/")}
                      </span>
                      <span>
                        {search.searchData.eventType?.charAt(0).toUpperCase() +
                          search.searchData.eventType?.slice(1)}{" "}
                      </span>
                    </div>
                    <div
                      className={styles.nav_section_search_mini_btn_img}
                      data-icon="true"
                      data-testid="little-search-icon"
                    >
                      <img
                        src="/assets/images/search_icon_blue.svg"
                        alt="Search"
                        width={28}
                        height={28}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className={`w-100 ${styles.nav_section_search_min_btn_text_new} ${styles.no_animation}`}
                  >
                    <span className={`mb-0`}>CLICK HERE TO SEE</span>
                    <br />
                    <span className={styles.bigger_text}>
                      INSTANT AVAILABILITY AND PRICING
                    </span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
        <a className={styles.scroll_down} href="#featured-video">
          <div className="animate__animated animate__fadeInDown animate__delay-2s animate__duration-1s">
            <div className={styles.scroll_down_object}>
              <span></span>
            </div>
          </div>
          <div className="animate__animated animate__fadeInUp animate__delay-2.2s animate__duration-1.2s">
            <h6 className={styles.scroll_down_title}>Scroll Down</h6>
          </div>
          <div className="animate__animated animate__fadeInUp animate__delay-2.4s animate__duration-1.2s">
            <p className={styles.scroll_down_text}>to discover more</p>
          </div>
        </a>
      </header>
      <SearchModal
        show={showModalForWeb}
        onHide={() => setShowModalForWeb(false)}
        dialogClassName={styles.search_modal_for_web}
        backdropClassName={styles.search_modal_for_web_backdrop}
        centered
      >
        <SearchModal.Body>
          <MegaSearchComponent
            headerSearch={headerSearch}
            headerSearchError={headerSearchError}
            disabledDate={disabledDate}
            onDatePickerSelect={onDatePickerSelect}
            onSelectChangeHandler={onSelectChangeHandler}
            onClickSearchBtn={onClickSearchBtn}
            onGooglePlaceSelect={onGooglePlaceSelect}
            showSearchText={true}
          />
        </SearchModal.Body>
        <div className={styles.cutom_search_modal_btn_container}>
          <button
            className={styles.cutom_search_modal_btn}
            onClick={onClickSearchBtn}
          >
            <span>Search</span>
            <img
              src="/assets/images/search_icon_blue.svg"
              alt="Search"
              width={60}
              height={60}
              loading="lazy"
            />
          </button>
        </div>

        <button
          onClick={() => setShowModalForWeb(false)}
          className={styles.search_for_web_close_btn}
        >
          X
        </button>
      </SearchModal>
      <SearchModal
        show={show}
        onHide={handleClose}
        className={styles.search_modal}
        centered
      >
        <SearchModal.Body>
          <button
            className={`img_btn ${styles.search_modal_close}`}
            onClick={handleClose}
          >
            X
          </button>
          <MegaSearchComponent
            headerSearch={headerSearch}
            headerSearchError={headerSearchError}
            disabledDate={disabledDate}
            onDatePickerSelect={onDatePickerSelect}
            onSelectChangeHandler={onSelectChangeHandler}
            onClickSearchBtn={onClickSearchBtn}
            onGooglePlaceSelect={onGooglePlaceSelect}
          />
        </SearchModal.Body>
      </SearchModal>
      <LoginComponent
        visible={loginModel}
        onCancelModal={closeModel}
        onInputChange={onInputChange}
        formError={formError}
        onLogin={onLogin}
        loginForm={loginForm}
        pwd_visible={visible}
        loader={loader}
        on_password_visible={on_password_visible}
        openSignUpModel={openSignUpModel}
        onClickOtpModel={onClickOtpModel}
        onClickforgotModel={onClickforgotModel}
      />
      <OtpVerifyComponent
        visible={otpVerify}
        onCancelModal={closeModel}
        openSignUpModel={openSignUpModel}
        otpCodeChange={otpCodeChange}
        otpForm={otpForm}
        onSubmitOtp={onSubmitOtp}
        formError={formError}
        resend_otp={resend_otp_api}
      />
      <SignUpComponent
        visible={signupModel}
        onCancelModal={closeModel}
        onInputChange={onInputSignUpChange}
        formError={formError}
        loginForm={loginForm}
        onSignUp={onSignUp}
        openModel={openModel}
        onPhoneInputChanges={onPhoneInputChanges}
        on_password_visible={on_password_visible}
        pwd_visible={visible}
        signUpForm={signUpForm}
        onChangeRadio={onChangeRadio}
        checkRadio={checkRadio}
      />
      <LoginWithOtpComponent
        visible={otpModel}
        onCancelModal={closeModel}
        onGetOtp={onGetOtp}
        onInputChange={onInputOtpChange}
        formError={formError}
        openModel={openModel}
        signUpForm={signUpForm}
        onPhoneInputChanges={onPhoneInputChanges}
      />

      <ForgotPasswordComponent
        visible={forgotModel}
        onCancelModal={closeModel}
        onInputforgotChange={onInputforgotChange}
        formError={formError}
        openModel={openModel}
        onSubmitForgot={onSubmitForgot}
        forgotForm={forgotForm}
      />
    </div>
  );
};

export default HomeHeaderComponent;
